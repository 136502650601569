
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { getPostList, likePost } from '@/api/post';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  components: {
    Pagination,
  },
})

export default class extends Vue {
  mounted() {
    this.getPostList();
  }

  // private handleClick() {
  //   this.likePost();
  // }

  // private likePost() {
  //   this.postLike.postUid = this.$route.params.postUid;
  //   const isLiked = this.active;

  //   likePost(this.$route.params.postUid, this.postLike).then((res) => {
  //     if (res.status === 200) {
  //       if (isLiked) {
  //         this.post.likeCount -= 1;
  //       } else {
  //         this.post.likeCount += 1;
  //       }
  //       this.active = !this.active;
  //     }
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // }

  // private updatePost() {
  //   this.$router.push({ name: 'PostUpdate', params: { postUid: this.postLike } });
  // }

  private listQuery = {
    boardUid: '67ec3f65-555e-41fe-a202-9608b7939c1f',
    page: 0,
    size: 10,
    searchType: '',
    searchValue: '',
  }

  private totalElements = 0;

  private postList = [];

  private loading = true;

  private getPostList() {
    this.loading = true;
    getPostList(this.listQuery).then((res) => {
      this.postList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });
  }

  private isNewPost(date: string) {
    let isNew = false;
    const postDate = moment(date);
    if (moment().diff(postDate, 'days') < 7) isNew = true;
    return isNew;
  }

  private goBack() {
    this.$router.go(-1);
  }
}
